/* eslint-disable camelcase */
import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../../../components/layout';
import ProductPage from '../../../components/products/ProductPage';

export const query = graphql`
  query QuerySingleProductAgain($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/products/" }) {
      frontmatter {
        title
        parent
        deployments
        logo {
          publicURL
        }
        hero {
          publicURL
        }
        headline
        tagline
        bundle {
          name
          slug
        }
        urls {
          marketplace
          docs
          migration
        }
        youtube_id
        features {
          title
          text
        }
        feature_images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
      slug
    }
  }
`;

const ProductPageWithoutLayout = ({ data }) => {
  if (!data.mdx) {
    // for blog & policies
    return null;
  }

  const { frontmatter } = data.mdx;
  const { title, headline } = frontmatter;

  return (
    <>
      <GatsbySeo language="en" title={title} description={headline} />

      <Layout pageTitle={title} showChrome={false}>
        <div className="iframed-product">
          <ProductPage data={data} />
        </div>
      </Layout>
    </>
  );
};

export default ProductPageWithoutLayout;
